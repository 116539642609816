<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top" v-if="isXian">
      <div class="top-select">
        <el-form :model="SerachInput" label-width="120px">
          <el-form-item label="添加时间">
            <el-col>
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="最后在线时间">
            <el-col>
              <el-date-picker
                v-model="overallForm.Onlinetime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="产品名">
            <el-col :span="8">
              <el-select
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.region"
                placeholder="请选择"
                clearable
                @change="regionSelcet(SerachInput.region)"
              >
                <el-option
                  v-for="item in optionssex"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="7">
              <el-select
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regions"
                placeholder="请选择"
                clearable
                @change="regionSelects(SerachInput.regions)"
              >
                <el-option
                  v-for="item in regionsOption"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regions"
                placeholder="请输入产品名"
                clearable
              >
              </el-input> -->
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="7">
              <el-select
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regionTwo"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in regionModel"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.regionTwo"
                placeholder="请输入型号"
                clearable
              >
              </el-input> -->
            </el-col>
            <!-- <el-col class="line" :span="1"> -</el-col>
            <el-col :span="8">
              <el-select
                @keyup.enter.native="handleQuery"
                v-model="SerachInput.productVersion"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in optionssexsType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col> -->
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="SerachInput.status"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in SerachStatus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售分组">
            <el-select
              v-model="SerachInput.groups"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectGroup"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="IMSI">
              <el-input
                v-model="SerachInput.imsi"
                placeholder="请输入内容"
                style="width: 150px"
                @keyup.enter.native="handleQuery"
                clearable
              ></el-input>
            </el-form-item> -->
          <el-form-item style="margin-right: 18px">
            <el-select
              @keyup.enter.native="handleQuery"
              v-model="SerachInput.selectC"
              placeholder="请选择"
              clearable
              style="margin-right: 10px"
              @change="selectData(SerachInput.selectC)"
            >
              <el-option
                v-for="item in selectOption"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="SerachInput.title"
              placeholder="请输入内容"
              style="width: 220px"
              @keyup.enter.native="handleQuery"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="是否删除">
            <el-select
              v-model="SerachInput.delFlag"
              placeholder="请输入"
              clearable
            >
              <el-option
                v-for="item in optionsDel"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电量">
            <el-input
              v-model="SerachInput.minElectric"
              placeholder="输入电量"
              style="width: 100px"
              clearable
            ></el-input>
            ~
            <el-input
              v-model="SerachInput.maxElectric"
              placeholder="输入电量"
              style="width: 100px"
              clearable
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="固件版本">
              <el-input
                v-model="SerachInput.paperVersion"
                placeholder="请输入内容"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item> -->
          <el-form-item label-width="60px">
            <img
              src="../../assets/images/query.png"
              alt
              @click="handleQuery"
              style="width: 49px"
            />
            <!-- <el-button
              class=""
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="handleQuery"
              >搜索</el-button
            > -->
          </el-form-item>
          <!-- <span class="con-size">IMEI</span> -->
        </el-form>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          ref="multipleTable"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="productTypeCn" label="分类" width="100">
          </el-table-column>
          <el-table-column prop="productName" label="产品名" width="120">
          </el-table-column>
          <el-table-column prop="productVersion" label="型号">
          </el-table-column>
          <el-table-column prop="imei" label="设备ID（IMEI）" width="160">
          </el-table-column>
          <el-table-column prop="iccid" label="ICCID" width="190">
          </el-table-column>
          <el-table-column prop="imsi" label="IMSI" width="180">
          </el-table-column>
          <el-table-column prop="localPhone" label="内置号码" width="120">
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="添加日期"
            width="160"
            :formatter="formatDate"
          ></el-table-column>
          <el-table-column
            prop="activateDate"
            label="激活日期"
            :formatter="activateDates"
            width="160"
          >
          </el-table-column>
          <el-table-column prop="familyId" label="家庭ID" width="120">
          </el-table-column>
          <el-table-column prop="lastOnlineDate" label="最近在线" width="160">
          </el-table-column>
          <el-table-column
            prop="groupName"
            label="销售分组"
            width="150"
          ></el-table-column>
          <el-table-column prop="electric" label="电量"></el-table-column>
          <el-table-column prop="signal" label="信号"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="delFlag" label="是否删除"></el-table-column>
          <el-table-column prop="status" label="设备状态"></el-table-column>
          <el-table-column prop="version" label="固件版本" width="100">
            <template slot-scope="scope">
              <el-button
                size="small"
                v-if="scope.row.version"
                @click="rowClick(scope.$index, scope.row)"
              >
                <span>{{ scope.row.version }}</span>
              </el-button>
              <span v-else>{{ scope.row.version }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="420">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                style="margin-right: 10px"
                >更改</el-button
              >
              <el-button
                size="mini"
                @click="outbound(scope.$index, scope.row)"
                style="margin-right: 10px"
                >出库时间</el-button
              >
              <el-button
                size="mini"
                @click="servicePeriod(scope.$index, scope.row)"
                style="margin-right: 10px"
                >服务有效期</el-button
              >
              <span v-if="shows">
                <el-button
                  size="mini"
                  v-if="scope.row.productType == '5' ? shows : ''"
                  @click="handLink(scope.$index, scope.row)"
                  >日志</el-button
                >
              </span>
              <span>
                <el-button
                  size="mini"
                  v-if="
                    scope.row.riskDelFlag == 1 || scope.row.riskDelFlag == null
                      ? shows
                      : ''
                  "
                  @click="abnormal(scope.row)"
                  style="
                    margin-left: 10px;
                    background: #f56c6c;
                    color: #fff;
                    border: none;
                  "
                  >异常</el-button
                >
                <el-button
                  size="mini"
                  v-else
                  @click="abnormal(scope.row)"
                  style="
                    margin-left: 10px;
                    background: #67c23a;
                    color: #fff;
                    border: none;
                  "
                  >恢复</el-button
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <el-button round class="top-btn" @click="addList"
            >增加设备+</el-button
          >
          <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        </div>
        <div class="bottom-right">
          <div class="bottom-b">
            <el-button
              type="info"
              style="
                width: 100%;
                height: 30px;
                font-size: 13px;
                padding: 8px;
                margin-left: 0px;
                background: #d78673;
              "
              @click="batChange"
              >批量更改</el-button
            >
          </div>
          <div class="bottom-b">
            <el-button
              type="info"
              style="
                width: 100%;
                height: 30px;
                font-size: 13px;
                padding: 8px;
                margin-left: 0px;
                background: #d78673;
              "
              @click="allInput"
              >批量导入</el-button
            >
          </div>
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
                @pagination="getEquipment"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据分析</el-button>
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加的弹框 -->
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
        <el-form
          :inline="true"
          :rules="rules"
          ref="formInline"
          :model="formInline"
          class="demo-form-inline"
          label-width="90px"
        >
          <el-form-item label="iccid" prop="iccid">
            <el-input
              v-model="formInline.iccid"
              placeholder="请输入iccid"
            ></el-input>
          </el-form-item>
          <el-form-item label="IMEI" prop="imei">
            <el-input
              v-model="formInline.imei"
              placeholder="请输入IMEI"
            ></el-input>
          </el-form-item>
          <el-form-item label="内置号码" prop="localPhone">
            <el-input
              v-model="formInline.localPhone"
              placeholder="请输入内置号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择状态">
            <el-select
              v-model="formInline.status"
              placeholder="请选择"
              @input="$forceUpdate()"
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in optionStatus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品分类">
            <el-select
              v-model="formInline.productId"
              @change="typeId(formInline.productId)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in unitName"
                :key="item.key"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
              <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :page-size="pageSizes"
                :current-page="pageCounts"
                layout="total, prev, pager, next"
                :total="unitNamePage"
                @pagination="getSelectList"
              >
              </el-pagination>
            </el-select>
          </el-form-item>
          <el-form-item label="销售分组">
            <el-select v-model="formInline.groupId" placeholder="请选择">
              <el-option label="未分组" :value="groupIds"></el-option>
              <el-option
                v-for="item in saleGroup"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="SN" v-if="isShow" prop="sn">
            <el-input v-model="formInline.sn" placeholder="请输入sn"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入"
              v-model="formInline.remark"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="preservation('formInline')"
            >保 存</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- 批量导入 -->
    <el-dialog
      title="批量导入"
      :visible.sync="allVisible"
      width="30%"
      append-to-body
    >
      <el-upload
        ref="upload"
        accept=".xls,.xlsx"
        action="#"
        :auto-upload="false"
        :multiple="false"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :http-request="uploadHttpRequest"
        :on-remove="fileRemove"
        :on-change="fileChange"
        :headers="myHeaders"
      >
        <el-button slot="trigger" size="small" type="primary" class="xuanWen"
          >选取文件</el-button
        >
        <div slot="tip" class="el-upload__tip">
          一次只能上传一个xls/xlsx文件，且不超过10M
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadAll">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 批量修改 -->
    <el-dialog :visible.sync="centerDialogVisible" width="46%" center>
      <el-form
        :model="changeBox"
        label-width="120px"
        style="margin: 2% 3% 2% -6%"
      >
        <el-form-item label="选择产品">
          <el-col :span="8">
            <el-select
              @keyup.enter.native="handleQuery"
              v-model="changeBox.region"
              placeholder="请选择"
              clearable
              @change="regionSelcet(changeBox.region)"
            >
              <el-option
                v-for="item in optionssex"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="1" style="text-align: center">-</el-col>
          <el-col :span="7">
            <el-select
              @keyup.enter.native="handleQuery"
              v-model="changeBox.regions"
              placeholder="请选择"
              clearable
              @change="regionSelects(changeBox.regions)"
            >
              <el-option
                v-for="item in regionsOption"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="1" style="text-align: center">-</el-col>
          <el-col :span="7">
            <el-select
              @keyup.enter.native="handleQuery"
              v-model="changeBox.regionTwo"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in regionModel"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="设备状态">
          <el-select v-model="changeBox.status" clearable placeholder="请选择">
            <el-option
              v-for="item in SerachStatus"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售分组">
          <el-select v-model="changeBox.groups" clearable placeholder="请选择">
            <el-option
              v-for="item in selectGroup"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 固件版本 -->
    <el-dialog
      title="固件版本"
      :visible.sync="dialogVisibleV"
      width="40%"
      :before-close="handleCloseV"
    >
      <el-input
        type="textarea"
        v-model="firVersion"
        placeholder="请输入内容"
        style="min-height: 33px; margin: 10px 20px 20px 22px; width: 94%"
      ></el-input>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleV = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleV = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <el-dialog :visible.sync="dialogVisiblefu" width="30%">
      <span style="display: inline-block; margin-bottom: 50px">{{
        appletsPreviewUrl
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblefu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisiblefu = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 出库时间 -->
    <div class="chuku">
      <el-dialog :visible.sync="dialogVisiblechu" width="40%" title="出库时间">
        <el-table :data="tableDatachu" style="width: 100%">
          <el-table-column
            prop="userName"
            label="操作员工"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="dateTime"
            label="时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="statusCn"
            label="出库状态"
            width="140px"
          ></el-table-column>
          <el-table-column
            prop="groupName"
            label="销售分组"
            width="160px"
          ></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </div>
    <!-- 服务有效期 -->
    <div class="fuwu">
      <el-dialog
        :visible.sync="dialogVisiblewu"
        width="45%"
        title="服务有效期"
        :before-close="handleClose"
      >
        <el-table :data="tableDatawu" style="width: 100%">
          <el-table-column
            prop="serviceName"
            label="服务项目"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="rmStatusCn"
            label="服务状态"
            width="120px"
          ></el-table-column>
          <!-- <el-table-column prop="rmPeriod" label="服务有效期" width="160px">
            <template slot-scope="scope">
              <span v-if="scope.row.rmPeriod != ''"
                >{{ scope.row.rmPeriod }}个月</span
              >
            </template>
          </el-table-column> -->
          <el-table-column
            prop="rmStart"
            label="服务开始时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="rmEnd"
            label="服务结束时间"
            width="160px"
          ></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import axios from "_axios@0.21.4@axios";
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  inject: ["reload"],
  data() {
    return {
      dialogVisiblechu: false,
      tableDatachu: [],
      tableDatawu: [],
      dialogVisiblewu: false,
      appletsPreviewUrl: "",
      dialogVisiblefu: false,
      isShow: false,
      optionsDel: [],
      clientHeight: document.body.clientHeight,
      dialogVisibleV: false,
      firVersion: "",
      shows: true,
      myHeaders: { "Content-Type": "multipart/form-data" },
      fileList: [],
      files: "",
      fileName: "",
      //增加的弹框
      dialogVisible: false,
      allVisible: false,
      isXian: true,
      unitName: [],
      //性别下拉
      optionssex: [],
      regionsOption: [],
      regionModel: [],
      optionssexs: [],
      optionStatus: [],
      saleGroup: [],
      SerachStatus: [],
      selectOption: [
        {
          code: "1",
          name: "IMEI",
        },
        {
          code: "2",
          name: "内置号码",
        },
        {
          code: "3",
          name: "ICCID",
        },
        {
          code: "4",
          name: "IMSI",
        },
      ],
      selectGroup: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "条目编号",
        },
        {
          value: "3",
          label: "分类",
        },
      ],
      overallForm: {
        time: [],
        Onlinetime: [],
      },
      formTime: "",
      formTimeTwo: "",
      formOnline: "",
      formOnlineTwo: "",
      SerachInput: {
        tiem: null,
        tiems: null,
        type: "",
        title: "",
        imsi: "",
        status: "",
        groups: "",
        selectC: "1",
        paperVersion: "",
      },
      changeBox: {},
      tiem: "",
      tiems: "",
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      form: {
        region: "",
        regions: "",
        regionTwo: "",
        value1: "",
      },
      value: "",
      input: "",
      title: "",
      tableData: [],
      //分页
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1, //网络请求的页码
      pageSizes: 10,
      pageCounts: 1, //网络请求的页码
      unitNamePage: 0,
      keyIds: [],
      unitNames: [],
      regionsId: null,
      regionsIds: null,
      ids: "",
      typed: "",
      //增加的表单
      formInline: {
        id: "",
        createDate: null,
        updateDate: null,
      },
      groupIds: null,
      rules: {
        imei: [{ required: true, message: "不能为空", trigger: "blur" }],
        sn: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      typeList: null,
      typeName: [],
      typeValue: "",
      typeValues: "",
      typeNames: "",
      centerDialogVisible: false,
      proList: [],
    };
  },
  created() {
    this.getEquipment();
    this.getDictsIF();
    this.yes();
    api.smallLei({ dictCode: "equipmentstatus" }).then((res) => {
      this.SerachStatus = res.data.result;
    });
    apiShout.getGroups().then((res) => {
      if (res.data.code === 200) {
        this.selectGroup = res.data.result;
        console.log(res, "选择销售分组");
      }
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    servicePeriod(index, row) {
      console.log("输出：", row);
      apiShout.openService({ id: row.id }).then((res) => {
        console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.dialogVisiblewu = true;
          if (row.productType == 1) {
            //医疗设备
            if (
              res.data.result.rmdhealthStart != null &&
              res.data.result.rmdhealthEnd != null
            ) {
              let date15 = res.data.result.rmdhealthEnd.split("-"); //例：将2020-12-21 的-去掉
              date15 = parseInt(date15[0]) * 12 + parseInt(date15[1]); //将字符串转换为数字格式
              let date16 = res.data.result.rmdhealthStart.split("-");
              date16 = parseInt(date16[0]) * 12 + parseInt(date16[1]);
              var obj = {
                serviceName: "健康提醒",
                rmStatusCn: res.data.result.rmdhealthStatusCn,
                rmStart: res.data.result.rmdhealthStart,
                rmEnd: res.data.result.rmdhealthEnd,
                rmPeriod: date15 - date16,
              };
              this.tableDatawu.push(obj);
            } else {
              var objs = {
                serviceName: "健康提醒",
                rmStatusCn: res.data.result.rmdhealthStatusCn,
                rmStart: res.data.result.rmdhealthStart,
                rmEnd: res.data.result.rmdhealthEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(objs);
            }
          } else if (row.productType == 2) {
            //安防设备
            if (
              res.data.result.rmdsecurityStart != null &&
              res.data.result.rmdsecurityEnd != null
            ) {
              let date17 = res.data.result.rmdsecurityEnd.split("-"); //例：将2020-12-21 的-去掉
              date17 = parseInt(date17[0]) * 12 + parseInt(date17[1]); //将字符串转换为数字格式
              let date18 = res.data.result.rmdsecurityStart.split("-");
              date18 = parseInt(date18[0]) * 12 + parseInt(date18[1]);
              var obj1 = {
                serviceName: "安防提醒",
                rmStatusCn: res.data.result.rmdsecurityStatusCn,
                rmStart: res.data.result.rmdsecurityStart,
                rmEnd: res.data.result.rmdsecurityEnd,
                rmPeriod: date17 - date18,
              };
              this.tableDatawu.push(obj1);
            } else {
              var obj1s = {
                serviceName: "安防提醒",
                rmStatusCn: res.data.result.rmdsecurityStatusCn,
                rmStart: res.data.result.rmdsecurityStart,
                rmEnd: res.data.result.rmdsecurityEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj1s);
            }
          } else if (row.productType == 3) {
            //适老设备
            if (
              res.data.result.rmdaidStart != null &&
              res.data.result.rmdaidEnd != null
            ) {
              let date19 = res.data.result.rmdaidEnd.split("-"); //例：将2020-12-21 的-去掉
              date19 = parseInt(date19[0]) * 12 + parseInt(date19[1]); //将字符串转换为数字格式
              let date20 = res.data.result.rmdaidStart.split("-");
              date20 = parseInt(date20[0]) * 12 + parseInt(date20[1]);
              var obj2 = {
                serviceName: "急救提醒",
                rmStatusCn: res.data.result.rmdaidStatusCn,
                rmStart: res.data.result.rmdaidStart,
                rmEnd: res.data.result.rmdaidEnd,
                rmPeriod: date19 - date20,
              };
              this.tableDatawu.push(obj2);
            } else {
              var obj2s = {
                serviceName: "急救提醒",
                rmStatusCn: res.data.result.rmdaidStatusCn,
                rmStart: res.data.result.rmdaidStart,
                rmEnd: res.data.result.rmdaidEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj2s);
            }
          } else if (row.productType == 5 || row.productType == 4) {
            //智能呼叫器||智能机器人
            if (
              res.data.result.basicStart != null &&
              res.data.result.basicEnd != null
            ) {
              let date1 = res.data.result.basicEnd.split("-"); //例：将2020-12-21 的-去掉
              date1 = parseInt(date1[0]) * 12 + parseInt(date1[1]); //将字符串转换为数字格式
              let date2 = res.data.result.basicStart.split("-");
              date2 = parseInt(date2[0]) * 12 + parseInt(date2[1]);
              // this.form.date = date1 - date2;
              var obj3 = {
                serviceName: "基础服务",
                rmStatusCn: res.data.result.basicStatusCn,
                rmStart: res.data.result.basicStart,
                rmEnd: res.data.result.basicEnd,
                rmPeriod: date1 - date2,
              };
              this.tableDatawu.push(obj3);
            } else {
              var obj3s = {
                serviceName: "基础服务",
                rmStatusCn: res.data.result.basicStatusCn,
                rmStart: res.data.result.basicStart,
                rmEnd: res.data.result.basicEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj3s);
            }

            if (
              res.data.result.cnoStart != null &&
              res.data.result.cnoEnd != null
            ) {
              let date3 = res.data.result.cnoEnd.split("-"); //例：将2020-12-21 的-去掉
              date3 = parseInt(date3[0]) * 12 + parseInt(date3[1]); //将字符串转换为数字格式
              let date4 = res.data.result.cnoStart.split("-");
              date4 = parseInt(date4[0]) * 12 + parseInt(date4[1]);
              var obj4 = {
                serviceName: "客服服务",
                rmStatusCn: res.data.result.cnoStatusCn,
                rmStart: res.data.result.cnoStart,
                rmEnd: res.data.result.cnoEnd,
                rmPeriod: date3 - date4,
              };
              this.tableDatawu.push(obj4);
            } else {
              var obj4s = {
                serviceName: "客服服务",
                rmStatusCn: res.data.result.cnoStatusCn,
                rmStart: res.data.result.cnoStart,
                rmEnd: res.data.result.cnoEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj4s);
            }

            if (
              res.data.result.ymStart != null &&
              res.data.result.ymEnd != null
            ) {
              let date5 = res.data.result.ymEnd.split("-"); //例：将2020-12-21 的-去掉
              date5 = parseInt(date5[0]) * 12 + parseInt(date5[1]); //将字符串转换为数字格式
              let date6 = res.data.result.ymStart.split("-");
              date6 = parseInt(date6[0]) * 12 + parseInt(date6[1]);
              var obj5 = {
                serviceName: "远盟服务",
                rmStatusCn: res.data.result.ymStatusCn,
                rmStart: res.data.result.ymStart,
                rmEnd: res.data.result.ymEnd,
                rmPeriod: date5 - date6,
              };
              this.tableDatawu.push(obj5);
            } else {
              var obj5s = {
                serviceName: "远盟服务",
                rmStatusCn: res.data.result.ymStatusCn,
                rmStart: res.data.result.ymStart,
                rmEnd: res.data.result.ymEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj5s);
            }
            if (
              res.data.result.contactStart != null &&
              res.data.result.contactEnd != null
            ) {
              let date7 = res.data.result.contactEnd.split("-"); //例：将2020-12-21 的-去掉
              date7 = parseInt(date7[0]) * 12 + parseInt(date7[1]); //将字符串转换为数字格式
              let date8 = res.data.result.contactStart.split("-");
              date8 = parseInt(date8[0]) * 12 + parseInt(date8[1]);
              var obj6 = {
                serviceName: "呼叫联系人服务",
                rmStatusCn: res.data.result.contactStatusCn,
                rmStart: res.data.result.contactStart,
                rmEnd: res.data.result.contactEnd,
                rmPeriod: date7 - date8,
              };
              this.tableDatawu.push(obj6);
            } else {
              var obj6s = {
                serviceName: "呼叫联系人服务",
                rmStatusCn: res.data.result.contactStatusCn,
                rmStart: res.data.result.contactStart,
                rmEnd: res.data.result.contactEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj6s);
            }
            if (
              res.data.result.healthStart != null &&
              res.data.result.healthEnd != null
            ) {
              let date9 = res.data.result.healthEnd.split("-"); //例：将2020-12-21 的-去掉
              date9 = parseInt(date9[0]) * 12 + parseInt(date9[1]); //将字符串转换为数字格式
              let date10 = res.data.result.healthStart.split("-");
              date10 = parseInt(date10[0]) * 12 + parseInt(date10[1]);
              var obj7 = {
                serviceName: "健康顾问",
                rmStatusCn: res.data.result.healthStatusCn,
                rmStart: res.data.result.healthStart,
                rmEnd: res.data.result.healthEnd,
                rmPeriod: date9 - date10,
              };
              this.tableDatawu.push(obj7);
            } else {
              var obj7s = {
                serviceName: "健康顾问",
                rmStatusCn: res.data.result.healthStatusCn,
                rmStart: res.data.result.healthStart,
                rmEnd: res.data.result.healthEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj7s);
            }
            if (
              res.data.result.lawStart != null &&
              res.data.result.lawEnd != null
            ) {
              let date11 = res.data.result.lawEnd.split("-"); //例：将2020-12-21 的-去掉
              date11 = parseInt(date11[0]) * 12 + parseInt(date11[1]); //将字符串转换为数字格式
              let date12 = res.data.result.lawStart.split("-");
              date12 = parseInt(date12[0]) * 12 + parseInt(date12[1]);
              var obj8 = {
                serviceName: "法律咨询",
                rmStatusCn: res.data.result.lawStatusCn,
                rmStart: res.data.result.lawStart,
                rmEnd: res.data.result.lawEnd,
                rmPeriod: date11 - date12,
              };
              this.tableDatawu.push(obj8);
            } else {
              var obj8s = {
                serviceName: "法律咨询",
                rmStatusCn: res.data.result.lawStatusCn,
                rmStart: res.data.result.lawStart,
                rmEnd: res.data.result.lawEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj8s);
            }
            if (
              res.data.result.careStart != null &&
              res.data.result.careEnd != null
            ) {
              let date13 = res.data.result.careEnd.split("-"); //例：将2020-12-21 的-去掉
              date13 = parseInt(date13[0]) * 12 + parseInt(date13[1]); //将字符串转换为数字格式
              let date14 = res.data.result.careStart.split("-");
              date14 = parseInt(date14[0]) * 12 + parseInt(date14[1]);
              var obj9 = {
                serviceName: "关爱慰问",
                rmStatusCn: res.data.result.careStatusCn,
                rmStart: res.data.result.careStart,
                rmEnd: res.data.result.careEnd,
                rmPeriod: date13 - date14,
              };
              this.tableDatawu.push(obj9);
            } else {
              var obj9s = {
                serviceName: "关爱慰问",
                rmStatusCn: res.data.result.careStatusCn,
                rmStart: res.data.result.careStart,
                rmEnd: res.data.result.careEnd,
                rmPeriod: "",
              };
              this.tableDatawu.push(obj9s);
            }
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    outbound(index, row) {
      console.log("00-0--:", row);
      apiShout.hisBy({ imei: row.imei }).then((res) => {
        console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.dialogVisiblechu = true;
          this.tableDatachu = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsDel = res.data.result;
        }
      });
    },
    rowClick(index, row) {
      console.log("index,row", index, row);
      let aa = JSON.parse(localStorage.getItem("tab"));
      aa.forEach((ele) => {
        // console.log('ele:',ele);
        if (ele.id == row.id) {
          // console.log('ele.ves:',ele.version);
          this.firVersion = ele.version;
        }
      });
      this.dialogVisibleV = true;
    },
    selectData(val) {
      console.log(val, "显示内容");
    },
    regionSelcet(val) {
      console.log(val, "查询type");
      this.typeValue = val;
      apiShout.getProTypes({ type: val }).then((res) => {
        console.log(res.data.result, "查看拿到的type和name");
        let objs = [];
        for (var i = 0; i < res.data.result.length; i++) {
          var objsCont = {};
          objsCont.code = res.data.result[i];
          objsCont.name = res.data.result[i];
          objs.push(objsCont);
        }
        this.regionsOption = objs;
        console.log(this.regionsOption, "查询");
      });
    },
    regionSelects(val) {
      console.log(val, "查看");
      console.log(val, "查看type2");
      let textOption = {
        type: this.typeValue,
        name: val,
      };
      console.log(textOption, "查询");
      apiShout.getProTypes(textOption).then((res) => {
        console.log(res.data.result, "查看拿到的type和name");
        let objs = [];
        for (var i = 0; i < res.data.result.length; i++) {
          var objsCont = {};
          objsCont.code = res.data.result[i];
          objsCont.name = res.data.result[i];
          objs.push(objsCont);
        }
        this.regionModel = objs;
        console.log(this.regionModel, "查询");
      });
    },
    formatDate(row, column) {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    activateDates(row, column) {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    batChange() {
      this.centerDialogVisible = true;
      this.changeBox = {};
      console.log("批量更改");
    },
    allInput() {
      this.allVisible = true;
    },
    uploadAll() {
      this.$refs.upload.submit();
      this.allVisible = false;
    },
    fileChange1(data) {
      console.log("0000:", data.target.files[0]);
    },
    beforeUpload(file) {
      this.files = file;
      this.fileName = file.name;
      //文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      }

      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 10;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过10M！");
      }
      return fileType && fileLimit;
    },
    uploadHttpRequest(param) {
      console.log("aaaa:", param);
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file", param.file); //添加文件对象
      // formData.append("fileName", param.file.name);
      console.log("56565611:", formData.getAll("file"));
      // const url = '/too/center/equipment/batchImportEquipment'; //上传地址
      let that = this;
      that
        .$http({
          url: "/too/center/equipment/batchImportEquipment",
          data: formData,
          method: "POST",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("批量导入：", res.data);
          if (res.data.result.length == 0) {
            this.$message({
              type: "success",
              message: "批量导入成功!",
            });
            this.$refs.upload.clearFiles();
          } else {
            this.$message.error("导入失败！");
            console.log("res.data:", res.data);
            this.appletsPreviewUrl = res.data.result;
            this.dialogVisiblefu = true;
            this.$refs.upload.clearFiles();
          }
        });
      //   apiShout.getbatIm({file:formData}).then(res=>{
      //   console.log('批量导入：',res.data);
      // })
      // this.$http
      //   .post(url, formData)
      //   .then((res) => {
      //     const {
      //       data: { code, mark },
      //     } = res;
      //     if (code === 0) {
      //       param.onSuccess(); // 上传成功的文件显示绿色的对勾
      //       this.uploadMark = mark;
      //     }
      //     return this.countData(this.uploadMark); //根据响应的 mark 值调用统计结果接口，返回一个promise以便进行链式调用
      //   })
      //   .then((res) => {
      //     //链式调用，统计结果的响应
      //     const {
      //       data: { code, data },
      //     } = res;
      //     if (code === 0) {
      //       console.log("统计结果", data);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("失败", err);
      //     param.onError(); //上传失败的文件会从文件列表中删除
      //   });
    },
    // 文件发生改变
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
        fileList.push(file);
      }
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    //导出
    exportList() {
      if (this.overallForm.time != null) {
        this.formTime = this.overallForm.time[0];
        this.formTimeTwo = this.overallForm.time[1];
      } else {
        this.formTime = null;
        this.formTimeTwo = null;
      }
      if (this.overallForm.Onlinetime != null) {
        this.formOnline = this.overallForm.Onlinetime[0];
        this.formOnlineTwo = this.overallForm.Onlinetime[1];
      } else {
        this.formOnline = null;
        this.formOnlineTwo = null;
      }
      // var param = {
      //   startTime: this.formTime,
      //   endTime: this.formTimeTwo,
      //   startOnlineTime: this.formOnline,
      //   endOnlineTime: this.formOnlineTwo,
      //   status: this.SerachInput.status,
      //   groupId: this.SerachInput.groups,
      //   imei: this.SerachInput.title,
      //   type: this.SerachInput.region,
      //   name: this.SerachInput.regions,
      // };
      let that = this;
      that
        .$http({
          url: "/too/center/equipment/exportEquipmentList",
          params: {
            startTime: this.formTime,
            endTime: this.formTimeTwo,
            startOnlineTime: this.formOnline,
            endOnlineTime: this.formOnlineTwo,
            status: this.SerachInput.status,
            groupId: this.SerachInput.groups,
            ctype: this.SerachInput.selectC,
            value: this.SerachInput.title,
            type: this.SerachInput.region,
            name: this.SerachInput.regions,
            version: this.SerachInput.regionTwo,
            paperVersion: this.SerachInput.paperVersion,
            minElectric: this.SerachInput.minElectric,
            maxElectric: this.SerachInput.maxElectric,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "终端管理" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
      // apiShout.exportEquipments(param).then(res => {
      //   console.log("导出数据：", res.data);
      //   const blob = new Blob([res.data], {
      //     type: "application/vnd.ms-excel",
      //     crossOrigin: "Anonymous",
      //   });
      //   const objectUrl = URL.createObjectURL(blob);
      //   window.location.href = objectUrl;
      // });
    },
    getDictsIF() {
      api.getSelectProduct({ dictCode: "producttype" }).then((res) => {
        this.optionssex = res.data.result;
      });
    },
    handleQuery() {
      this.getEquipment();
    },
    getEquipment() {
      if (this.overallForm.time != null) {
        this.formTime = this.overallForm.time[0];
        this.formTimeTwo = this.overallForm.time[1];
      } else {
        this.formTime = null;
        this.formTimeTwo = null;
      }
      if (this.overallForm.Onlinetime != null) {
        this.formOnline = this.overallForm.Onlinetime[0];
        this.formOnlineTwo = this.overallForm.Onlinetime[1];
      } else {
        this.formOnline = null;
        this.formOnlineTwo = null;
      }
      if (this.SerachInput.minElectric > this.SerachInput.maxElectric) {
        this.$message.error("请输入正确的电量");
        return;
      }
      apiShout
        .getEquipments({
          pageCount: this.pageCount,
          pageSize: this.pageSize,
          status: this.SerachInput.status,
          groupId: this.SerachInput.groups,
          ctype: this.SerachInput.selectC,
          value: this.SerachInput.title,
          type: this.SerachInput.region,
          name: this.SerachInput.regions,
          version: this.SerachInput.regionTwo,
          startTime: this.formTime,
          endTime: this.formTimeTwo,
          startOnlineTime: this.formOnline,
          endOnlineTime: this.formOnlineTwo,
          delFlag: this.SerachInput.delFlag,
          minElectric: this.SerachInput.minElectric,
          maxElectric: this.SerachInput.maxElectric,
        })
        .then((res) => {
          console.log("终端管理：", res.data);
          this.tableData = res.data.result.data;
          localStorage.setItem("tab", JSON.stringify(res.data.result.data));
          this.totalCount = res.data.result.page.totalCount;
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].electric != "未知") {
              this.tableData[i].electric = this.tableData[i].electric + "%";
              if (
                this.tableData[i].version != null &&
                this.tableData[i].version.length > 8
              ) {
                this.tableData[i].version = this.tableData[i].version.slice(-8);
              }
            }
          }
        });
    },
    getSelectList() {
      apiShout
        .getProduct({ pageCount: this.pageCounts, pageSize: this.pageSizes })
        .then((res) => {
          const data = [];
          var usrAll = [];
          const keyId = [];
          if (res.data.code == 200) {
            console.log("输出点击的是那个？", res.data.result.data);
            usrAll = res.data.result.data;
            this.proList = res.data.result.data;
            if (usrAll) {
              usrAll.forEach((usr) => {
                keyId.push(usr.id);
                data.push({
                  label: usr.type + " " + usr.name + " " + usr.version,
                  value: usr.id, //userId  phonenumber
                  key: usr.id, //userId  phonenumber
                });
              });
            }
            this.unitNamePage = res.data.result.page.totalCount;
          }
          this.unitName = data;
          this.keyIds = keyId;
          return data;
        });
    },
    //查询id
    typeId(val) {
      console.log("输出选的是那个产品：", val);
      this.typeList = val;
      this.proList.forEach((ele) => {
        console.log("shuchu:ele", ele);
        if (ele.id == val) {
          console.log("输出显示相等的是哪个？", ele.typeCode);
          var numPro = ele.typeCode;
          if (numPro == 7 || numPro == 8) {
            this.isShow = true;
          } else {
            this.isShow = false;
          }
        }
      });
    },
    handleCurrentChanges(pageCounts) {
      this.pageCounts = pageCounts;
      this.getSelectList();
    },
    // 分页
    handleSizeChanges(size) {
      this.pageSizes = size;
      this.getSelectList();
    },
    // 分页
    handleSizeChange(size) {
      this.pageSize = size;
      this.getEquipment();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getEquipment();
    },
    //增加
    addList() {
      this.dialogVisible = true;
      this.formInline = {};
      this.isShow = false;
      //销售分组 的下拉框
      apiShout.getGroups().then((res) => {
        console.log("销售分组：", res.data);
        if (res.data.code == 200) {
          this.saleGroup = res.data.result;
        }
      });
      api.smallLei({ dictCode: "equipmentstatus" }).then((res) => {
        this.optionStatus = res.data.result;
        this.formInline.status = res.data.result[2].code;
      });
      this.getSelectList();
    },
    // 取消
    cancel() {
      this.dialogVisible = false;
      this.formInline = {};
    },
    //保存
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formInline.id) {
            this.formInline.createDate = [];
            this.formInline.updateDate = [];
            this.formInline.productId = this.typeList;
            apiShout
              .updateEquipment(
                this.formInline,
                this.formInline.createDate,
                this.formInline.updateDate,
                this.formInline.productId
              )
              .then((res) => {
                if (res.data.code === 200) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.dialogVisible = false;
                  this.reload();
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          } else {
            this.formInline.createDate = [];
            this.formInline.updateDate = [];
            this.formInline.productId = this.typeList;
            apiShout
              .insertEquis(
                this.formInline,
                this.formInline.createDate,
                this.formInline.updateDate,
                this.formInline.productId
              )
              .then((res) => {
                if (res.data.code === 200) {
                  this.$message({
                    type: "success",
                    message: "新增成功!",
                  });
                  this.dialogVisible = false;
                  this.reload();
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    handleEdit(index, row) {
      console.log(row, "查询数据");
      this.formInline.id = row.id;
      this.ByIdsAll = row.id;
      this.isShow = false;
      //销售分组 的下拉框
      apiShout.getGroups().then((res) => {
        console.log("销售分组：", res.data);
        if (res.data.code == 200) {
          this.saleGroup = res.data.result;
        }
      });
      apiShout.getEquipmentByIds({ id: this.ByIdsAll }).then((res) => {
        if (res.data.code === 200) {
          this.dialogVisible = true;
          this.formInline = res.data.result;
          this.textContent = res.data.result.text;
          this.typeList = res.data.result.productId;
          this.typeName =
            res.data.result.productTypeCn +
            " " +
            res.data.result.productName +
            " " +
            res.data.result.productVersion;
          this.formInline.productId = this.typeName;
          // for (let a = 0; a < this.typeOption.length; a++) {
          //   if (this.formInline.type == this.typeOption[a].id) {
          //     this.formInline.type = this.typeOption[a].name;
          //   }
          // }
        }
      });
      api.smallLei({ dictCode: "equipmentstatus" }).then((res) => {
        this.optionStatus = res.data.result;
      });
      this.getSelectList();
    },
    handleCloseV() {
      this.dialogVisibleV = false;
    },
    handleClose() {
      this.dialogVisiblewu = false;
      this.tableDatawu = [];
    },
    // 跳转外链
    handLink(index, row) {
      apiShout.getparTitle({ title: "paperlog" }).then((res) => {
        // console.log('转接：',res.data);
        if (res.data.code == 200) {
          console.log(res.data.result, "res.data");
          console.log(row, "查询数据");
          if (row.imei != "") {
            window.open(res.data.result + "?imei=" + row.imei);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //操作中异常或者恢复处理
    abnormal(row) {
      console.log(row, "查询异常或者恢复的值");
      if (row.riskDelFlag == 0) {
        this.$confirm("确定恢复吗, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            apiShout.delRisk({ equipmentId: row.id }).then((res) => {
              console.log(res, "恢复处理");
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "恢复成功!",
                });
                this.getEquipment();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$confirm("确定处理为异常吗, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            apiShout.addRisk({ equipmentId: row.id }).then((res) => {
              console.log(res, "异常处理");
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "处理成功!",
                });
                this.getEquipment();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top {
  height: 200px;
  margin: 20px 40px 20px 25px;
}
.top-btn {
  float: left;
  margin-right: 30px;
  background: #d78673;
  color: #ffffff;
}

.top-search {
  position: relative;
}
.con-size {
  margin: 0px 10px 20px 40px;
}
.top-img {
  margin-left: 10px;
  position: absolute;
  top: 60px;
}
.table-content {
  padding: 30px 10px 10px 0px;
}
/deep/ .el-table .cell {
  text-align: center;
}
.bottom-right {
  float: right;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.bottom-b {
  float: right;
  margin-right: 10%;
}
.top-btn1 {
  height: 20px;
  line-height: 0;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  background: #d78673;
  color: #ffffff;
}
.bottom-fen {
  margin-top: 30px;
}
.bottom-left {
  position: relative;
}
.bottom-shu {
  font-size: 18px;
  // font-weight: bold;
  color: #606266;
}
.healLabel {
  width: 400px;
  height: 50px;
  background-color: #ebe9e9;
  line-height: 50px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
  letter-spacing: 1px;
}
.offen {
  margin-left: 150px;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #909399;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  height: auto;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 28px;
}
//表格
.table {
  /deep/ .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none !important;
}
/deep/.el-dialog__body {
  padding: 0;
  margin-left: 2%;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
//上传文件
/deep/ .el-upload {
  input[type="file"] {
    display: none;
  }
}
.xuanWen {
  background-color: #d78673;
  color: #fff;
  border-color: #d78673;
  padding: 6px;
  line-height: 15px;
}
.fuwu {
  /deep/.el-dialog__body {
    padding: 0;
    margin: auto 7% !important;
  }
}
.chuku {
  /deep/.el-dialog__body {
    padding: 0;
    margin: auto 7% !important;
  }
}
</style>